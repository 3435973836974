'use client'
import { RootState } from '@/app/globalRedux/store'
import { updateLocalStorageCount } from '@/lib/utils'
import { sendGTMEvent } from '@next/third-parties/google'
import { useParams, usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

interface SalesOfferTrackerType {
  sales_offer_card_id?: string
  sales_offer_card_clicked_id?: string
  sales_offer_card_lang: string
  sales_offer_card_company_id: string
  userId: number
  isAllCookiesEnabled: boolean
}

export const useGTMSalesOfferTracker = (companyId: number) => {
  const pathName = usePathname()
  const params = useParams()
  const { userId, isAllCookiesEnabled } = useSelector(
    (state: RootState) => state.user
  )

  useEffect(() => {
    updateLocalStorageCount(
      String(params?.salesOfferId),
      'sales_offer_detail_visit'
    )

    sendGTMEvent({
      event: 'sales_offer_detail_visit',
      sales_offer_id: params?.salesOfferId,
      lang: params?.lng ?? 'en',
      company_id: companyId,
      current_user_id:
        userId > 0
          ? isAllCookiesEnabled
            ? String(userId)
            : 'not-consented'
          : 'non-logged-in',
    })
  }, [pathName, companyId])
}

export const useContactSellerSalesOfferGTMEvent = () => {
  const params = useParams()
  const sendContactSellerEvent = ({ companyID }: { companyID: number }) => {
    updateLocalStorageCount(
      String(params?.salesOfferId),
      'sales_offer_contact_seller_click'
    )
    sendGTMEvent({
      event: 'sales_offer_contact_seller_click',
      sales_offer_id: params?.salesOfferId,
      company_id: companyID,
    })
  }

  return sendContactSellerEvent
}

export const userIdValue = (
  userId: number,
  isAllCookiesEnabled: boolean
): string => {
  if (userId > 0) {
    if (isAllCookiesEnabled) {
      return String(userId)
    } else {
      return 'not-consented'
    }
  }
  return 'non-logged-in'
}
export const GTMASOCardViewTracker = ({
  sales_offer_card_id,
  sales_offer_card_lang,
  sales_offer_card_company_id,
  userId,
  isAllCookiesEnabled,
}: SalesOfferTrackerType) => {
  updateLocalStorageCount(String(sales_offer_card_id), 'sales_offer_card_view')
  sendGTMEvent({
    event: 'sales_offer_card_view',
    sales_offer_id: sales_offer_card_id,
    lang: sales_offer_card_lang,
    company_id: sales_offer_card_company_id,
    current_user_id: userIdValue(userId, isAllCookiesEnabled),
  })
}

export const GTMSOCardClickTracker = ({
  sales_offer_card_clicked_id,
  sales_offer_card_lang,
  sales_offer_card_company_id,
  userId,
  isAllCookiesEnabled,
}: SalesOfferTrackerType) => {
  updateLocalStorageCount(
    String(sales_offer_card_clicked_id),
    'sales_offer_card_clicked'
  )

  sendGTMEvent({
    event: 'sales_offer_card_clicked',
    sales_offer_id: sales_offer_card_clicked_id,
    lang: sales_offer_card_lang,
    company_id: sales_offer_card_company_id,
    current_user_id:
      userId > 0
        ? isAllCookiesEnabled
          ? String(userId)
          : 'not-consented'
        : 'non-logged-in',
  })
}
