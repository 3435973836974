'use client'
import GTMCompanyTracker from './GTMCompanyTracker'
import GTMPageTracker from './GTMPageTracker'

const GTMProvider = () => {
  return (
    <>
      <GTMPageTracker />
      <GTMCompanyTracker />
    </>
  )
}

export default GTMProvider
