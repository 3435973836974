import { Middleware } from '@reduxjs/toolkit'
import { setCompanyPageNo } from '../filter/companyFilter'
const companyMiddleWare: Middleware = (storeAPI) => (next) => (action: any) => {
  const currentPageNo = storeAPI.getState().companyFilter.pageNo
  const result = next(action)
  const isCompanyFilterAction = action?.type?.split('/')[0] == 'companyFilter'
  if (
    isCompanyFilterAction &&
    action.type !== 'companyFilter/setCompanyPageNo' &&
    currentPageNo !== 1
  ) {
    storeAPI.dispatch(setCompanyPageNo(1))
  }

  return result
}

export default companyMiddleWare
