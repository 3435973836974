import { Middleware } from '@reduxjs/toolkit'
import { setNewsPageNo } from '../filter/newsFilter'
const newsMiddleWare: Middleware = (storeAPI) => (next) => (action: any) => {
  const currentPageNo = storeAPI.getState().newsFilter.pageNo
  const result = next(action)
  const isNewsFilterAction = action?.type?.split('/')[0] == 'newsFilter'
  if (
    isNewsFilterAction &&
    action.type !== 'newsFilter/setNewsPageNo' &&
    currentPageNo !== 1
  ) {
    storeAPI.dispatch(setNewsPageNo(1))
  }

  return result
}

export default newsMiddleWare
