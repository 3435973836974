import { Middleware } from '@reduxjs/toolkit'
import { setProductPageNo } from '../filter/productFilter'
const productMiddleWare: Middleware = (storeAPI) => (next) => (action: any) => {
  const currentPageNo = storeAPI.getState().productFilter.pageNo
  const result = next(action)
  const isProductFilterAction = action?.type.split('/')[0] == 'productFilter'
  if (
    isProductFilterAction &&
    action.type !== 'productFilter/setProductPageNo' &&
    currentPageNo !== 1
  ) {
    storeAPI.dispatch(setProductPageNo(1))
  }

  return result
}

export default productMiddleWare
