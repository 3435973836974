import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ProtectedRoutesType {
  signUp: boolean
  verifyMail: boolean
  memberShipPricing: boolean
  stripePayment: boolean
  stripeSuccess: boolean
  signUpPage: string
}

const initialState: ProtectedRoutesType = {
  signUp: true,
  verifyMail: false,
  memberShipPricing: false,
  stripePayment: false,
  stripeSuccess: false,
  signUpPage: 'Get-Details',
}

export const protectedRouteSlice = createSlice({
  name: 'protectedRoutes',
  initialState,
  reducers: {
    setSignUpPage: (state, action) => {
      state.signUpPage = action.payload
    },
    toggleSignUp: (state, action) => {
      state.signUp = action.payload
    },
    toggleVerifyMail: (state, action) => {
      state.verifyMail = action.payload
    },
    toggleMemberShipPricing: (state, action) => {
      state.memberShipPricing = action.payload
    },
    toggleStripePayment: (state, action) => {
      state.stripePayment = action.payload
    },
    toggleStripeSuccess: (state, action) => {
      state.stripeSuccess = action.payload
    },
    setRouteState: (
      state,
      action: PayloadAction<Partial<ProtectedRoutesType>>
    ) => {
      return { ...state, ...action.payload }
    },
  },
})

export const {
  setSignUpPage,
  toggleSignUp,
  toggleVerifyMail,
  toggleMemberShipPricing,
  toggleStripePayment,
  toggleStripeSuccess,
  setRouteState,
} = protectedRouteSlice.actions

export default protectedRouteSlice.reducer
