'use client'
import { updateLocalStorageCount } from '@/lib/utils'
import { sendGTMEvent } from '@next/third-parties/google'
import { useParams, usePathname } from 'next/navigation'
import { useEffect } from 'react'

const GTMCompanyTracker = () => {
  const pathName = usePathname()
  const params = useParams()
  const company_urls = [
    'fruits_and_vegetables',
    'transport_logistics',
    'pre-post-harvest',
  ]

  useEffect(() => {
    const isCompanyUrl = company_urls.some((url) => pathName.includes(url))
    if (isCompanyUrl) {
      updateLocalStorageCount(String(params?.id), 'mini_website_visit')
    }
    if (isCompanyUrl) {
      sendGTMEvent({
        event: 'mini_website_visit',
        friendly_url: params?.id,
        lang: params?.lng ?? 'en',
      })
    }
  }, [pathName, params])

  return null
}

export default GTMCompanyTracker

export const gtmFromRedirectedToCompany = (
  from: string,
  companyFriendlyURL: string,
  companyId: string
) => {
  if (from) {
    updateLocalStorageCount(from, 'company_visit_redirect')
  }
  sendGTMEvent({
    event: 'company_visit_redirect',
    company_redirect_from: from,
    friendly_url: companyFriendlyURL,
    company_id: companyId,
  })
}

export const gtmcompanyCardViewTracker = (companyId: number) => {
  if (companyId) {
    updateLocalStorageCount(companyId, 'company_card_view')
  }
  sendGTMEvent({
    event: 'company_card_view',
    company_id: companyId,
  })
}

export const gtmContactUsFormSubmission = (
  friendlyUrl: string,
  companyId: string
) => {
  if (companyId) {
    updateLocalStorageCount(companyId, 'company_contact_us')
  }
  sendGTMEvent({
    event: 'company_contact_us',
    friendly_url: friendlyUrl,
    company_id: companyId,
  })
}

export const gtmAskQuoteFormSubmission = (
  friendlyUrl: string,
  productId: number,
  companyId: number
) => {
  if (productId) {
    updateLocalStorageCount(productId, 'company_ask_quote')
  }
  sendGTMEvent({
    event: 'company_ask_quote',
    friendly_url: friendlyUrl,
    product_id: productId,
    company_id: companyId,
  })
}
