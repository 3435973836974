'use client'
import { initialState } from '@/app/types/signUpType'
import { createSlice } from '@reduxjs/toolkit'

export const signupSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setShowEmailVerification: (state, action) => {
      state.showEmailVerification = action.payload
    },
    setShowSuccessModal: (state, action) => {
      state.showSuccessModal = action.payload
    },
    setActivityName: (state, action) => {
      state.activityName = action.payload
    },
    setCountryName: (state, action) => {
      state.countryName = action.payload
    },
    setBussinessSectorName: (state, action) => {
      state.businessSectorName = action.payload
    },
    setOpen: (state, action) => {
      state.open = action.payload
    },
    setStage: (state, action) => {
      state.stage = action.payload
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setWebsite: (state, action) => {
      state.website = action.payload
    },
    setPhone: (state, action) => {
      state.phone = action.payload
    },
    setCountry: (state, action) => {
      state.country_id = action.payload
    },
    setActivity: (state, action) => {
      state.activity = action.payload
    },
    setUserPassword: (state, action) => {
      state.userPassword = action.payload
    },
    setUserConfirmPassword: (state, action) => {
      state.userConfirmPassword = action.payload
    },
    setCompanyPassword: (state, action) => {
      state.companyPassword = action.payload
    },
    setCompanyConfirmPassword: (state, action) => {
      state.companyConfirmPassword = action.payload
    },
    setPlan: (state, action) => {
      state.plan = action.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
      state.stage = 1
    },
    setAddress: (state, action) => {
      state.address = action.payload
    },
    setBusinessSectorId: (state, action) => {
      state.businessSector = action.payload
    },
    setCountryCode: (state, action) => {
      state.countryPhoneCode = action.payload
    },
    setDialCode: (state, action) => {
      state.countryDialCode = action.payload
    },
    setEmoji: (state, action) => {
      state.emoji = action.payload
    },
    setPlanID: (state, action) => {
      state.planId = action.payload
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload
    },
    setLastName: (state, action) => {
      state.lastName = action.payload
    },
    setDepartmentId: (state, action) => {
      state.departmentId = action.payload
    },
    setSubmitted: (state, action) => {
      state.submitted = action.payload
    },
    setOptEmail: (state, action) => {
      state.optEmail = action.payload
    },
    setAgreeTerms: (state, action) => {
      state.agreeTerms = action.payload
    },
    setSignUpLoader: (state, action) => {
      state.signUpLoader = action.payload
    },
    setCountryEmoji: (state, action) => {
      state.countryEmoji = action.payload
    },
    setCompanyInputValue: (state, action) => {
      state.companyInputValue = action.payload
    },
    setCompanyList: (state, action) => {
      state.companyList = action.payload
    },
    setActualCompanyList: (state, action) => {
      state.actualCompanyList = action.payload
    },

    setPhoneError: (state, action) => {
      state.phoneError = action.payload
    },

    setNameError: (state, action) => {
      state.nameError = action.payload
    },
    setisCompanyFormSubmitted: (state, action) => {
      state.isCompanyFormSubmitted = action.payload
    },
    resetSignUp: (state) => {
      ;(state.companyName = ''),
        (state.email = ''),
        (state.website = ''),
        (state.phone = ''),
        (state.country_id = 0),
        (state.countryName = ''),
        (state.businessSectorName = ''),
        (state.address = ''),
        (state.businessSector = -1),
        (state.activity = -1),
        (state.userPassword = ''),
        (state.userConfirmPassword = ''),
        (state.companyPassword = ''),
        (state.companyConfirmPassword = ''),
        (state.stage = -1),
        (state.activeTab = 'member'),
        (state.countryPhoneCode = -1),
        (state.emoji = ''),
        (state.activityName = ''),
        (state.showSuccessModal = false),
        (state.showEmailVerification = false),
        (state.countryDialCode = {
          name: '',
          id: -1,
          dial_code: '',
          country_code: '',
          emoji: '',
          flag: '',
        }),
        (state.firstName = ''),
        (state.lastName = ''),
        (state.departmentId == ''),
        (state.submitted = false),
        (state.optEmail = true),
        (state.agreeTerms = false),
        (state.countryEmoji = ''),
        (state.companyInputValue = ''),
        (state.companyList = []),
        (state.actualCompanyList = []),
        (state.phoneError = null),
        (state.nameError = null),
        (state.isCompanyFormSubmitted = false)
    },
  },
})

export const {
  setCompanyName,
  setEmail,
  setWebsite,
  setPhone,
  setCountry,
  setActivity,
  setUserPassword,
  setUserConfirmPassword,
  setCompanyPassword,
  setCompanyConfirmPassword,
  setPlan,
  resetSignUp,
  setStage,
  setActiveTab,
  setAddress,
  setBusinessSectorId,
  setCountryCode,
  setDialCode,
  setEmoji,
  setCompanyId,
  setPlanID,
  setOpen,
  setActivityName,
  setCountryName,
  setBussinessSectorName,
  setShowSuccessModal,
  setShowEmailVerification,
  setFirstName,
  setLastName,
  setDepartmentId,
  setSubmitted,
  setOptEmail,
  setAgreeTerms,
  setSignUpLoader,
  setCountryEmoji,
  setCompanyInputValue,
  setCompanyList,
  setActualCompanyList,
  setPhoneError,
  setNameError,
  setisCompanyFormSubmitted
} = signupSlice.actions
export default signupSlice.reducer
