import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface EventFilterState {
  searchTerm: string
  month: string
  language: string
  pageNo: number
  order: string
  selectedEventSortOption: string
  clearFilter: boolean
}

const initialState: EventFilterState = {
  searchTerm: '',
  month: '',
  language: 'English',
  pageNo: 1,
  order: 'desc',
  selectedEventSortOption: 'upcoming',
  clearFilter: false,
}

export const EventFilterSlice = createSlice({
  name: 'eventFilter',
  initialState,
  reducers: {
    setEventOrder: (state, action: PayloadAction<string>) => {
      state.order = action.payload
    },
    setEventPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload
    },
    setEventSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload
    },
    setLanguages: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setAddOrRemoveMonth: (state, action: PayloadAction<string>) => {
      if (state.month == action.payload) {
        state.month = ''
      } else {
        state.month = action.payload
      }
    },
    setEventSortSelectedOption: (state, action) => {
      state.selectedEventSortOption = action.payload
    },
    clearEventFilters: (state) => {
      state.clearFilter = !state.clearFilter
      ;;(state.selectedEventSortOption = 'upcoming'), (state.month = '')
    },
  },
})

export const {
  setEventOrder,
  setEventSearchTerm,
  setEventPageNo,
  setEventSortSelectedOption,
  setAddOrRemoveMonth,
  setLanguages,
  clearEventFilters,
} = EventFilterSlice.actions

export default EventFilterSlice.reducer
