import { createSlice } from '@reduxjs/toolkit'
import { ConstantDataState } from '../types/constantDataType'
import {
  getProductFamilyList,
  getLanguagesList,
  getCountryList,
  getUnits,
  getBusinessSectorList,
  getCertificationsList,
  getFunctionList,
  getProductTypeList,
  getCuratedLanguageList,
  getCriteriaList,
  getSubscriptionsList,
} from '@/services/generalDataServices'
import { getActivityList } from '../../services/generalDataServices'
import { sortByName } from '../utils/formatListData'

const initialState: ConstantDataState = {
  loading: false,
  languages: [],
  curatedLanguages: [],
  countriesList: [],
  functionList: [],
  productFamilyList: [],
  productTypesList: [],
  activityList: [],
  currencyList: [],
  qualityList: [],
  unitsList: [],
  time_frequency: [], 
  incotermsList: [],
  businessSectorList: [],
  certificationsList: [],
  productCertificationsList: [],
  criteriaList: [],
  subscriptionsList: [],
  error: '',
}

export const constantsSlice = createSlice({
  name: 'constantsSlice',
  initialState,
  reducers: {
    setLanguages: (state, action) => {
      state.languages = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLanguagesList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getLanguagesList.fulfilled, (state: any, action) => {
      state.loading = false
      state.languages = action.payload
    })
    builder.addCase(getLanguagesList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getCuratedLanguageList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCuratedLanguageList.fulfilled, (state: any, action) => {
      state.loading = false
      state.curatedLanguages = sortByName(action.payload)
    })
    builder.addCase(getCuratedLanguageList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getCountryList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCountryList.fulfilled, (state: any, action) => {
      state.loading = false
      state.countriesList = sortByName(action.payload)
    })
    builder.addCase(getCountryList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getFunctionList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFunctionList.fulfilled, (state: any, action) => {
      state.loading = false
      state.functionList = sortByName(action.payload?.departments)
    })
    builder.addCase(getFunctionList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getProductFamilyList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProductFamilyList.fulfilled, (state: any, action) => {
      state.loading = false
      state.productFamilyList = sortByName(action.payload.product_families)
    })
    builder.addCase(getProductFamilyList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getUnits.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUnits.fulfilled, (state: any, action) => {
      state.loading = false
      state.currencyList = sortByName(action.payload.currencies)
      state.qualityList = sortByName(action.payload.qualities)
      state.unitsList = sortByName(action.payload.units)
      state.incotermsList = sortByName(action.payload.payment_types)
      state.time_frequency = sortByName(action.payload.time_frequency)
    })
    builder.addCase(getUnits.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getActivityList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getActivityList.fulfilled, (state: any, action) => {
      state.loading = false
      state.activityList = sortByName(action.payload)
    })
    builder.addCase(getActivityList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getBusinessSectorList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getBusinessSectorList.fulfilled, (state: any, action) => {
      state.loading = false
      state.businessSectorList = sortByName(action.payload.industries)
    })
    builder.addCase(getBusinessSectorList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getCertificationsList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCertificationsList.fulfilled, (state: any, action) => {
      state.loading = false
      state.certificationsList = action.payload?.certifications
        ? action.payload?.certifications?.filter(
            (cert: any) => cert.certification_type === 'company'
          )
        : []
      state.certificationsList = sortByName(state.certificationsList)
      state.productCertificationsList = action.payload?.certifications
        ? action.payload?.certifications?.filter(
            (cert: any) => cert.certification_type === 'product'
          )
        : []
      state.productCertificationsList = sortByName(
        state.productCertificationsList
      )
    })
    builder.addCase(getCertificationsList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getProductTypeList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProductTypeList.fulfilled, (state: any, action) => {
      state.loading = false
      state.productTypesList = sortByName(action.payload.product_types)
    })
    builder.addCase(getProductTypeList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getCriteriaList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCriteriaList.fulfilled, (state: any, action) => {
      state.loading = false
      state.criteriaList = sortByName(action.payload.criteria)
    })
    builder.addCase(getCriteriaList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(getSubscriptionsList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getSubscriptionsList.fulfilled, (state: any, action) => {
      state.loading = false
      state.subscriptionsList = sortByName(action.payload?.subscription_type)
    })
    builder.addCase(getSubscriptionsList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const { setLanguages } = constantsSlice.actions
export default constantsSlice.reducer
