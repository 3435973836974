import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Product {
  id: number
  name: string
  checked: boolean
}
export interface ProductState {
  cultivationType: string[]
  pageNo: number
  order: string
  searchTerm: string
  sector: string
  productFvProductOrigin: string[]
  productFruitVegetableActivity: string[]
  productFVAvailability: string
  productCompanyLocation: string[]
  productFvLanguage: string
  productPphLanguage: string
  productPphAllProducts: Product[]
  productFilterSelectedProducts: Product[]
  productPphSelectedRegion: string[]
  productPpHSelectedFamily: string[]
  productTlSelectedCompanyLocation: string[]
  productTlLanguage: string
  productSelectedSortOption: string
  clearFilter: boolean
}

const initialState: ProductState = {
  sector: 'Fruit & Vegetable',
  cultivationType: [],
  pageNo: 1,
  order: 'desc',
  searchTerm: '',
  productFvProductOrigin: [],
  productFruitVegetableActivity: [],
  productFVAvailability: '',
  productCompanyLocation: [],
  productFvLanguage: 'English',
  productPphAllProducts: [],
  productFilterSelectedProducts: [],
  productPphSelectedRegion: [],
  productPpHSelectedFamily: [],
  productPphLanguage: 'English',
  productTlSelectedCompanyLocation: [],
  productTlLanguage: 'English',
  productSelectedSortOption: 'Publish Date',
  clearFilter: false,
}

export const productFvStaticSupplier = [
  'Grower',
  'Greenhouses Grower',
  'Cooperative',
  'Exporter',
  'Shipper',
  'Broker',
  'Packer',
  'Ripening room',
  'SK Logistics',
]
export const productFvStaticBuyer = [
  'Buying central',
  'Importer',
  'Wholesaler/Distributor',
  'Hypermarket',
  'Catering',
  'Processor',
  'Supermarket',
  'Secondary wholesaler',
]

export const productFilterSlice = createSlice({
  name: 'productFilter',
  initialState,
  reducers: {
    setProductFvLanguage: (state, action: PayloadAction<string>) => {
      state.productFvLanguage = action.payload
    },
    addOrRemoveFruitVegetableActivity: (
      state,
      action: PayloadAction<string>
    ) => {
      if (state.productFruitVegetableActivity.includes(action.payload)) {
        state.productFruitVegetableActivity =
          state.productFruitVegetableActivity.filter(
            (activity) => activity !== action.payload
          )
      } else {
        state.productFruitVegetableActivity = [
          ...state.productFruitVegetableActivity,
          action.payload,
        ]
      }
    },
    setSelectAllProductBuyers: (state) => {
      const activitySet = new Set(state.productFruitVegetableActivity)
      if (productFvStaticBuyer.every((buyer) => activitySet.has(buyer))) {
        const newFruitVegetableActivity =
          state.productFruitVegetableActivity.filter(
            (activity) => !productFvStaticBuyer.includes(activity)
          )
        state.productFruitVegetableActivity = newFruitVegetableActivity
      } else {
        const buyerList: string[] = productFvStaticBuyer.filter(
          (buyer) => !state.productFruitVegetableActivity.includes(buyer)
        )

        state.productFruitVegetableActivity = [
          ...state.productFruitVegetableActivity,
          ...buyerList,
        ]
      }
    },
    setSelectAllProductSuppliers: (state) => {
      const activitySet = new Set(state.productFruitVegetableActivity)
      if (
        productFvStaticSupplier.every((supplier) => activitySet.has(supplier))
      ) {
        const newFruitVegetableActivity =
          state.productFruitVegetableActivity.filter(
            (activity) => !productFvStaticSupplier.includes(activity)
          )
        state.productFruitVegetableActivity = newFruitVegetableActivity
      } else {
        const supplierList: string[] = productFvStaticSupplier.filter(
          (supplier) => !state.productFruitVegetableActivity.includes(supplier)
        )

        state.productFruitVegetableActivity = [
          ...state.productFruitVegetableActivity,
          ...supplierList,
        ]
      }
    },

    setAddOrRemoveAvailability: (state, action: PayloadAction<string>) => {
      if (state.productFVAvailability == action.payload) {
        state.productFVAvailability = ''
      } else {
        state.productFVAvailability = action.payload
      }
    },
    addAllCultivationType: (state) => {
      state.cultivationType = ['Conventional', 'Organic']
    },
    removeAllCultivationType: (state) => {
      state.cultivationType = []
    },
    setCultivationType: (state, action: PayloadAction<string>) => {
      if (state.cultivationType.includes(action.payload)) {
        state.cultivationType = state.cultivationType.filter(
          (cType) => cType !== action.payload
        )
      } else {
        state.cultivationType = [...state.cultivationType, action.payload]
      }
    },

    addOrRemoveFvProductOrigin: (state, action: PayloadAction<string>) => {
      if (state.productFvProductOrigin.includes(action.payload)) {
        state.productFvProductOrigin = state.productFvProductOrigin.filter(
          (origin) => origin !== action.payload
        )
      } else {
        state.productFvProductOrigin = [
          ...state.productFvProductOrigin,
          action.payload,
        ]
      }
    },
    addOrRemoveProductCompanyLocation: (
      state,
      action: PayloadAction<string>
    ) => {
      if (state.productCompanyLocation.includes(action.payload)) {
        state.productCompanyLocation = state.productCompanyLocation.filter(
          (origin) => origin !== action.payload
        )
      } else {
        state.productCompanyLocation = [
          ...state.productCompanyLocation,
          action.payload,
        ]
      }
    },
    setproductPphLanguage: (state, action: PayloadAction<string>) => {
      state.productPphLanguage = action.payload
    },
    setProductSelectAllProducts: (state, action: PayloadAction<Product[]>) => {
      if (
        state.productFilterSelectedProducts.length !== action.payload.length
      ) {
        state.productFilterSelectedProducts = action.payload
      } else if (
        state.productFilterSelectedProducts.length == action.payload.length
      ) {
        state.productFilterSelectedProducts = []
      }
    },

    addOrRemoveProductFilterSelectedProduct: (
      state,
      action: PayloadAction<Product>
    ) => {
      if (
        state.productFilterSelectedProducts.some(
          (p) => p.id === action.payload.id
        )
      ) {
        state.productFilterSelectedProducts =
          state.productFilterSelectedProducts.filter(
            (p) => p.id !== action.payload.id
          )
      } else {
        state.productFilterSelectedProducts = [
          ...state.productFilterSelectedProducts,
          action.payload,
        ]
      }
    },
    addOrRemoveProductPphSelectedFamily: (
      state,
      action: PayloadAction<string>
    ) => {
      if (state.productPpHSelectedFamily.includes(action.payload)) {
        state.productPpHSelectedFamily = state.productPpHSelectedFamily.filter(
          (pphFamily) => pphFamily != action.payload
        )
      } else {
        state.productPpHSelectedFamily = [
          ...state.productPpHSelectedFamily,
          action.payload,
        ]
      }
    },
    addOrRemoveProductPphSelectedRegion: (
      state,
      action: PayloadAction<string>
    ) => {
      if (!state.productPphSelectedRegion.includes(action.payload)) {
        state.productPphSelectedRegion = [
          ...state.productPphSelectedRegion,
          action.payload,
        ]
      } else {
        state.productPphSelectedRegion = state.productPphSelectedRegion.filter(
          (region) => region !== action.payload
        )
      }
    },

    addOrRemoveProductTlSelectedLocation: (
      state,
      action: PayloadAction<string>
    ) => {
      if (state.productTlSelectedCompanyLocation.includes(action.payload)) {
        state.productTlSelectedCompanyLocation =
          state.productTlSelectedCompanyLocation.filter(
            (item) => item !== action.payload
          )
      } else {
        state.productTlSelectedCompanyLocation = [
          ...state.productTlSelectedCompanyLocation,
          action.payload,
        ]
      }
    },
    setProductTlLanguage: (state, action: PayloadAction<string>) => {
      state.productTlLanguage = action.payload
    },
    setProductOrder: (state, action) => {
      state.order = action.payload
    },
    setProductPageNo: (state, action) => {
      state.pageNo = action.payload
    },
    setProductSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setProductSector: (state, action) => {
      state.sector = action.payload
    },
    setProductSelectedSortOption: (state, action) => {
      state.productSelectedSortOption = action.payload
    },
    setProductFiltersToInitialState: (state) => {
      state.clearFilter = !state.clearFilter
      ;(state.productSelectedSortOption = 'Publish Date'),
        (state.sector = 'Fruit & Vegetable'),
        (state.cultivationType = []),
        // (state.pageNo = 1),
        (state.order = 'desc'),
        (state.productFvProductOrigin = []),
        (state.productFruitVegetableActivity = []),
        (state.productFVAvailability = ''),
        (state.productCompanyLocation = []),
        (state.productFvLanguage = 'English'),
        (state.productFilterSelectedProducts = []),
        (state.productPphSelectedRegion = []),
        (state.productPpHSelectedFamily = []),
        (state.productPphLanguage = 'English'),
        (state.productTlSelectedCompanyLocation = []),
        (state.productTlLanguage = 'English')
    },
  },
})

export const {
  setCultivationType,
  setProductOrder,
  setProductPageNo,
  setProductSearchTerm,
  setProductSector,
  addAllCultivationType,
  removeAllCultivationType,
  addOrRemoveFvProductOrigin,
  addOrRemoveFruitVegetableActivity,
  addOrRemoveProductCompanyLocation,
  setAddOrRemoveAvailability,
  setProductFvLanguage,
  setproductPphLanguage,
  addOrRemoveProductFilterSelectedProduct,
  addOrRemoveProductPphSelectedFamily,
  addOrRemoveProductPphSelectedRegion,
  addOrRemoveProductTlSelectedLocation,
  setProductTlLanguage,
  setProductFiltersToInitialState,
  setSelectAllProductSuppliers,
  setSelectAllProductBuyers,
  setProductSelectAllProducts,
  setProductSelectedSortOption,
} = productFilterSlice.actions

export default productFilterSlice.reducer
