import { Middleware } from '@reduxjs/toolkit'
import { setEventPageNo } from '../filter/eventFilter'
const eventMiddleWare: Middleware = (storeAPI) => (next) => (action: any) => {
  const currentPageNo = storeAPI.getState().eventFilter.pageNo
  const result = next(action)
  const isEventFilterAction = action?.type.split('/')[0] == 'eventFilter'
  if (
    isEventFilterAction &&
    action.type !== 'eventFilter/setEventPageNo' &&
    currentPageNo !== 1
  ) {
    storeAPI.dispatch(setEventPageNo(1))
  }

  return result
}

export default eventMiddleWare
