import { CompanyList, CountryType } from './constantDataType'

export interface SignUpState {
  companyName: string
  email: string
  website: string
  phone: string
  countryPhoneCode: number
  countryDialCode: CountryType
  country_id: number
  countryName: string
  address: string
  businessSector: number
  activity: number
  userPassword: string
  userConfirmPassword: string
  companyPassword: string
  companyConfirmPassword: string
  plan: string
  stage: number
  activeTab: string
  emoji: string
  companyId: number
  planId: number
  open: boolean
  businessSectorName: string
  activityName: string
  showSuccessModal: boolean
  showEmailVerification: boolean
  firstName: string
  lastName: string
  departmentId: string
  submitted: boolean
  optEmail: boolean
  agreeTerms: boolean
  signUpLoader: boolean
  countryEmoji: string
  companyInputValue: string
  companyList: string[]
  actualCompanyList: CompanyList[]
  phoneError: string | null
  nameError:string | null
  isCompanyFormSubmitted:boolean
}

export const initialState: SignUpState = {
  companyName: '',
  email: '',
  website: '',
  phone: '',
  country_id: 0,
  countryName: '',
  businessSectorName: '',
  address: '',
  businessSector: -1,
  activity: -1,
  userPassword: '',
  userConfirmPassword: '',
  companyPassword: '',
  companyConfirmPassword: '',
  plan: '',
  stage: -1,
  activeTab: 'member',
  countryPhoneCode: -1,
  emoji: '',
  companyId: -1,
  planId: -1,
  open: false,
  activityName: '',
  showSuccessModal: false,
  showEmailVerification: false,
  countryDialCode: {
    name: '',
    id: -1,
    dial_code: '',
    country_code: '',
    emoji: '',
    flag: '',
  },
  firstName: '',
  lastName: '',
  departmentId: '',
  submitted: false,
  optEmail: true,
  agreeTerms: false,
  signUpLoader: false,
  countryEmoji: '',
  companyInputValue: '',
  companyList:[],
  actualCompanyList:[],
  phoneError:null,
  nameError:null,
  isCompanyFormSubmitted:false
}
