'use client'
import { RootState } from '@/app/globalRedux/store'
import { sendGTMEvent } from '@next/third-parties/google'
import { useParams, usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { userIdValue } from './GTMSalesOfferTracker'

const GTMPageTracker = () => {
  const pathname = usePathname()
  const params = useParams<{ lng: string }>()
  const { userSubscription, userId, isAllCookiesEnabled } = useSelector(
    (state: RootState) => state.user
  )
  useEffect(() => {
    sendGTMEvent({
      event: 'page_view',
      current_day: new Date().getDay(),
      lang: params?.lng ?? 'en',
      current_user_id: userIdValue(userId, isAllCookiesEnabled),
      user_subscription: `${userSubscription ? (isAllCookiesEnabled ? userSubscription : 'not-consented') : 'non_logged_in'}`,
    })
  }, [pathname, userId, isAllCookiesEnabled])
  return null
}

export default GTMPageTracker
