'use client'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

const ViewportSetter = () => {
  const pathName = usePathname()
  useEffect(() => {
    const setViewport = () => {
      const viewportMeta = document.querySelector('meta[name="viewport"]')
      if (viewportMeta) {
        if (window?.innerWidth >= 768) {
          viewportMeta.setAttribute(
            'content',
            'width=1350, initial-scale=0, user-scalable=yes'
          )
        } else {
          viewportMeta.setAttribute(
            'content',
            'width=device-width, initial-scale=1.0, user-scalable=yes'
          )
        }
      }
    }

    setViewport()
    window.addEventListener('load', setViewport)
    window.addEventListener('resize', setViewport)

    return () => {
      window.addEventListener('load', setViewport)
      window.removeEventListener('resize', setViewport)
    }
  }, [window?.innerWidth, pathName])

  return null
}

export default ViewportSetter
