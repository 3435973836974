import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.API_URL}`,
    credentials: 'include',
  }),

  tagTypes: [
    'ProductServices',
    'Countries',
    'Activities',
    'Logistics',
    'Alerts',
    'Member',
    'AllSearch',
    'userDetails',
  ],
  endpoints: () => ({}),
})
