'use client'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Activity {
  [key: string]: any
}

interface SupplierBuyer {
  value: string
  activityCount: number
}
export interface Region {
  name: string
  count: number
  checked: false
}

interface ProductFamilyPayloadList {
  id: number
  name: string
}
export const companyRegion: Region[] = [
  { name: 'Europe', count: 0, checked: false },
  { name: 'Americas', count: 0, checked: false },
  { name: 'Asia', count: 0, checked: false },
  { name: 'Africa', count: 0, checked: false },
  { name: 'Oceania', count: 0, checked: false },
]

interface CompanyFilterList {
  fvStaticRegion: string[]
  fvListedRegion: Region[]
  pphListedRegion: Region[]
  tlListedRegion: Region[]
  fvListedSupplier: SupplierBuyer[]
  fvListedBuyer: SupplierBuyer[]
  fvStaticSupplier: string[]
  fvStaticBuyer: string[]
  pphListedProductFamily: { count: number; value: string; checked: boolean }[]
}

const initialState: CompanyFilterList = {
  pphListedProductFamily: [],
  fvStaticRegion: ['Europe', 'Americas', 'Asia', 'Africa', 'Oceania'],
  fvListedRegion: companyRegion,
  pphListedRegion: companyRegion,
  tlListedRegion: companyRegion,
  fvListedSupplier: [
    { activityCount: 0, value: 'Grower' },
    { activityCount: 0, value: 'Greenhouses Grower' },
    { activityCount: 0, value: 'Cooperative' },
    { activityCount: 0, value: 'Exporter' },
    { activityCount: 0, value: 'Shipper' },
    { activityCount: 0, value: 'Broker' },
    { activityCount: 0, value: 'Packer' },
    { activityCount: 0, value: 'Ripening room' },
    { activityCount: 0, value: 'SK Logistics' },
  ],
  fvListedBuyer: [
    { activityCount: 0, value: 'Buying central' },
    { activityCount: 0, value: 'Importer' },
    { activityCount: 0, value: 'Wholesaler/Distributor' },
    { activityCount: 0, value: 'Hypermarket' },
    { activityCount: 0, value: 'Catering' },
    { activityCount: 0, value: 'Processor' },
    { activityCount: 0, value: 'Supermarket' },
    { activityCount: 0, value: 'Secondary wholesaler' },
  ],
  fvStaticSupplier: [
    'Grower',
    'Greenhouses Grower',
    'Cooperative',
    'Exporter',
    'Shipper',
    'Broker',
    'Packer',
    'Ripening room',
    'SK Logistics',
  ],
  fvStaticBuyer: [
    'Buying central',
    'Importer',
    'Wholesaler/Distributor',
    'Hypermarket',
    'Catering',
    'Processor',
    'Supermarket',
    'Secondary wholesaler',
  ],
}

export const companyFilterList = createSlice({
  name: 'companyFilterList',
  initialState,
  reducers: {
    setListedBuyerSupplier: (state, action: PayloadAction<Activity[]>) => {
      // Reset activity counts to 0
      state.fvListedSupplier.forEach((supplier) => {
        supplier.activityCount = 0
      })
      state.fvListedBuyer.forEach((buyer) => {
        buyer.activityCount = 0
      })

      // Update activity counts based on payload
      action.payload.forEach((activity) => {
        const [key, count] = Object.entries(activity)[0]

        if (state.fvStaticSupplier.includes(key)) {
          const supplier = state.fvListedSupplier.find(
            (supplier) => supplier.value === key
          )
          if (supplier) {
            supplier.activityCount = count
          }
        } else if (state.fvStaticBuyer.includes(key)) {
          const buyer = state.fvListedBuyer.find((buyer) => buyer.value === key)
          if (buyer) {
            buyer.activityCount = count
          }
        }
      })
    },

    setCountFvListedRegion: (state, action: PayloadAction<Activity[]>) => {
      state.fvListedRegion.forEach((region) => {
        region.count = 0
      })
      action.payload.forEach((activity) => {
        const [key, count] = Object.entries(activity)[0]

        if (state.fvStaticRegion.includes(key)) {
          const region = state.fvListedRegion.find(
            (region) => region.name === key
          )
          if (region) {
            region.count = count
          }
        }
      })
    },
    setCountPphListedRegion: (state, action: PayloadAction<Activity[]>) => {
      state.pphListedRegion.forEach((region) => {
        region.count = 0
      })
      action.payload.forEach((activity) => {
        const [key, count] = Object.entries(activity)[0]

        if (state.fvStaticRegion.includes(key)) {
          const region = state.pphListedRegion.find(
            (region) => region.name === key
          )
          if (region) {
            region.count = count
          }
        }
      })
    },
    setCountTlListedRegion: (state, action: PayloadAction<Activity[]>) => {
      state.tlListedRegion.forEach((region) => {
        region.count = 0
      })
      action.payload.forEach((activity) => {
        const [key, count] = Object.entries(activity)[0]

        if (state.fvStaticRegion.includes(key)) {
          const region = state.tlListedRegion.find(
            (region) => region.name === key
          )
          if (region) {
            region.count = count
          }
        }
      })
    },

    setFvRegion: (state, action) => {
      state.fvListedRegion = action.payload
    },

    setPphListedRegion: (state, action) => {
      state.pphListedRegion = action?.payload
    },
    setTlListedRegion: (state, action) => {
      state.tlListedRegion = action.payload
    },
    setListedProductFamily: (
      state,
      action: PayloadAction<ProductFamilyPayloadList[]>
    ) => {
      state.pphListedProductFamily = action.payload?.map((item) => ({
        count: 0,
        value: item.name,
        checked: false,
      }))
    },
    setPphListedFamily: (state, action) => {
      state.pphListedProductFamily = action.payload
    },

    setFvListedProductFamilyCount: (
      state,
      action: PayloadAction<
        {
          [key: string]: any
        }[]
      >
    ) => {
      const product_family = action.payload
      const productOriginalCountList = state.pphListedProductFamily
      product_family.forEach((family) => {
        const [key, count] = Object.entries(family)[0]
        const item = productOriginalCountList.find(
          (product) => product.value === key
        )
        if (item) {
          item.count = count
        }
      })
      state.pphListedProductFamily = productOriginalCountList
    },
  },
})

export const {
  setListedBuyerSupplier,
  setCountFvListedRegion,
  setListedProductFamily,
  setFvListedProductFamilyCount,
  setCountPphListedRegion,
  setFvRegion,
  setPphListedFamily,
  setPphListedRegion,
  setCountTlListedRegion,
  setTlListedRegion,
} = companyFilterList.actions
export default companyFilterList.reducer
