import { FeatureFlagType } from '@/app/[lng]/Classses/FetatureFlag'
import { createSlice } from '@reduxjs/toolkit'

const initialState: { feature_flags: FeatureFlagType[] } = {
  feature_flags: [],
}
export const featureFlag = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    setFeatureFlag: (state, action) => {
      state.feature_flags = action.payload
    },
  },
})
export const { setFeatureFlag } = featureFlag.actions

export default featureFlag.reducer
