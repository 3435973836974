'use client'
import { setIsAllCookiesEnabled } from '@/app/globalRedux/userStore/userStore'
import { getCookies, getDomain } from '@/lib/utils'
import { useRouter } from 'next/navigation'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

interface CookieContextType {
  openNecessaryCookies: boolean
  setOpenNecessaryCookies: React.Dispatch<React.SetStateAction<boolean>>
  openThirdPartyCookies: boolean
  setThirdPartyCookies: React.Dispatch<React.SetStateAction<boolean>>
  cookieModalNo: number
  setCookieModalNo: React.Dispatch<React.SetStateAction<number>>
  openCookieModal: boolean
  setOpenCookieModal: React.Dispatch<React.SetStateAction<boolean>>
  thirdPartyCookieEnabled: boolean
  setThirdPartyCookieEnabled: React.Dispatch<React.SetStateAction<boolean>>
  setCookies: () => void
}

const CookieContext = createContext<CookieContextType | undefined>(undefined)

export const CookieProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [openCookieModal, setOpenCookieModal] = useState<boolean>(false)
  const [openNecessaryCookies, setOpenNecessaryCookies] =
    useState<boolean>(false)
  const [openThirdPartyCookies, setThirdPartyCookies] = useState<boolean>(true)
  const [cookieModalNo, setCookieModalNo] = useState<number>(1)
  const [thirdPartyCookieEnabled, setThirdPartyCookieEnabled] =
    useState<boolean>(true)

  const setCookies = () => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 100)
    document.cookie = `basic_cookie=enabled; Path=/; Expires=${date.toUTCString()}; domain=${getDomain()}; secure`
    if (thirdPartyCookieEnabled) {
      document.cookie = `third_party=enabled; Path=/; Expires=${date.toUTCString()}; domain=${getDomain()}; secure`
    }
    setOpenCookieModal(false)
    router.refresh()
  }

  useEffect(() => {
    const cookies = getCookies()
    if (cookies.basic_cookie) {
      setOpenCookieModal(false)
    } else {
      setOpenCookieModal(true)
    }
    if (getCookies()?.third_party == 'enabled') {
      dispatch(setIsAllCookiesEnabled(true))
    }
  }, [])

  return (
    <CookieContext.Provider
      value={{
        openNecessaryCookies,
        setOpenNecessaryCookies,
        openThirdPartyCookies,
        setThirdPartyCookies,
        cookieModalNo,
        setCookieModalNo,
        openCookieModal,
        setOpenCookieModal,
        thirdPartyCookieEnabled,
        setThirdPartyCookieEnabled,
        setCookies,
      }}
    >
      {children}
    </CookieContext.Provider>
  )
}

export const useCookieContext = () => {
  const context = useContext(CookieContext)
  if (context === undefined) {
    throw new Error('useCookieContext must be used within a CookieProvider')
  }
  return context
}
