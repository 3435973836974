import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NewsFilterState {
  searchTerm: string
  criterias: string[]
  language: string
  pageNo: number
  order: string
  selectedNewsSortOption: string
  clearFilter: boolean
}

const initialState: NewsFilterState = {
  searchTerm: '',
  criterias: [],
  language: 'English',
  pageNo: 1,
  order: 'desc',
  selectedNewsSortOption: 'Publish Date',
  clearFilter: false,
}

export const newsFilterSlice = createSlice({
  name: 'newsFilter',
  initialState,
  reducers: {
    setNewsFilterData: (
      state,
      action: PayloadAction<Partial<NewsFilterState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setNewsOrder: (state, action: PayloadAction<string>) => {
      state.order = action.payload
    },
    setNewsPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload
    },
    setNewsSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload
    },

    setCriterias: (state, action: PayloadAction<string[]>) => {
      state.criterias = action.payload
    },
    addOrRemoveCriteria: (state, action: PayloadAction<string>) => {
      if (state.criterias.includes(action.payload)) {
        state.criterias = state.criterias.filter(
          (criteria) => criteria !== action.payload
        )
      } else {
        state.criterias.push(action.payload)
      }
    },

    setLanguages: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setNewsSortSelectedOption: (state, action) => {
      state.selectedNewsSortOption = action.payload
    },
    clearFilters: (state) => {
      state.clearFilter = !state.clearFilter
      ;(state.selectedNewsSortOption = 'Publish Date'),
        (state.language = 'English')
      state.criterias = []
    },
  },
})

export const {
  setNewsOrder,
  setNewsSearchTerm,
  setCriterias,
  addOrRemoveCriteria,
  setLanguages,
  setNewsPageNo,
  setNewsSortSelectedOption,
  clearFilters,
  setNewsFilterData,
} = newsFilterSlice.actions

export default newsFilterSlice.reducer
